import React, { useReducer, useState } from 'react';
import './ShoppingListView.css';
import rightArrow from "../icons/right_arrow.png"
import cancelIcon from "../icons/cancel.png"
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import { AisleWithIngredients, Ingredient, Recipe, getIngredients, shoppingList as getShoppingList } from '../data';
import ShoppingList, { displayQuantityValue } from './ShoppingList';
import { buildEditRecipe, buildRecipeName } from './RecipesTable';
import { IngredientWithId, ShoppingListCategory, ShoppingListContext, ShoppingListDispatchContext, ShoppingListIngredient, nextUniqueId, noneCategoryId, shoppingListContextReducer, useShoppingList, useShoppingListDispatch } from '../contexts/ShoppingListContext';
import AddIngredient, { IngredientOption } from './AddIngredient';
import { getShortWeekDay } from '../dates';

export type RecipeServingTuple = [recipe_id: number, date: number, servings?: number];
export type ShopMeals = (RecipeServingTuple | number)[];

export interface RecipeWithIngredients {
    recipe: Recipe;
    ingredients: IngredientWithId[];
    date?: Date;
}

export interface LoaderData {
    aisles: AisleWithIngredients[]; 
    ingredients: Map<string, AisleWithIngredients>;
    recipes: RecipeWithIngredients[];
    shoppingList: ShoppingListCategory[];
}

function getMealsToShop(url: URL): ShopMeals {
    const mealsQuery = url.searchParams.get("meals") || "";
    if (mealsQuery) {
        try {
            const meals = JSON.parse(mealsQuery) as ShopMeals;
            return meals;
        }
        catch (e) {
            console.error("Could not parse 'meals' query", e);
        }
    }
    const recipeIdsQuery = url.searchParams.get("ids") || "";
    return recipeIdsQuery
        .split(",")
        .map(part => parseInt(part, 10))
        .filter(Number.isFinite);
}

export async function loader({ request }: LoaderFunctionArgs): Promise<LoaderData> {
    const url = new URL(request.url);
    const meals = getMealsToShop(url);

    const [res, aisles] = await Promise.all([
        getShoppingList(meals.map(entry => typeof entry === "number" ? { recipe_id: entry } : { recipe_id: entry[0], servings: entry[2] })),
        getIngredients()
    ]);

    const ingredients = new Map<string, AisleWithIngredients>();
    for (const aisle of aisles) {
        for (const ingredient of aisle.ingredients) {
            ingredients.set(ingredient, aisle);
        }
    }

    const shoppingList: ShoppingListCategory[] = aisles.map((c, index) => {
        const cId = "c" + index;
        return {
            name: c.name,
            uniqueId: cId,
            id: c.id,
            items: []
        }
    });
    shoppingList.push({
        uniqueId: noneCategoryId,
        items: [],
        name: null
    });

    return {
        aisles,
        ingredients,
        recipes: res.recipes.map(({ recipe, ingredients }, index) => {
            const meal = meals[index];
            return {
                recipe,
                ingredients: ingredients.map(i => ({...i, uniqueId: nextUniqueId() })),
                date: typeof meal === "number" ? undefined : new Date(meal[1])
            };
        }),
        shoppingList
    };
}

enum IngredientState {
    None,
    Added,
    Removed
}

interface RecipeIngredientProps {
    ingredient: Ingredient;
    state: IngredientState;
    onAdd: () => void;
    onSkip: () => void;
}

function RecipeIngredient({ ingredient: i, state, onAdd, onSkip }: RecipeIngredientProps) {
    return <div className='flex-row'>
        <div style={{ opacity: state !== IngredientState.None ? "15%" : 1 }}>{i.quantity ? displayQuantityValue(i.quantity) : <></>} {i.unit} {i.name}</div>
        <div style={{flexGrow: 1, minWidth: "10px" }}></div>
        <img
            className={'shopping-list-recipe-ingredient remove ' + (state !== IngredientState.Removed ? 'clickable' : 'active')}
            src={cancelIcon}
            onClick={onSkip}
        ></img>
        <div style={{ minWidth: "5px" }}></div>
        <img
            className={'shopping-list-recipe-ingredient add ' + (state !== IngredientState.Added ? 'clickable' : 'active')}
            src={rightArrow}
            onClick={onAdd}
        ></img>
    </div>;
}

function RecipesWithIngredientsToBuy({ recipes }: { recipes: RecipeWithIngredients[]; }) {
    const { ingredientToCategory, usedIngredients } = useShoppingList();
    const dispatch = useShoppingListDispatch();

    return <ul style={{ paddingLeft: "15px" }}>
        {recipes.map(r => {
            return <li key={r.recipe.id}>
                <div style={{ display: "flex", gap: "10px" }}>{buildRecipeName(r.recipe)} {buildEditRecipe(r.recipe)}</div>
                <ul>
                    {r.ingredients.map((i, index) => {
                        const state = usedIngredients.get(i.uniqueId) ?? IngredientState.None;

                        const onAdd = () => {
                            if (state === IngredientState.Added) return;

                            const category = ingredientToCategory.get(i.name);
                            dispatch({
                                type: 'add',
                                category: category?.name,
                                ingredient: i,
                                source: r.recipe.name,
                                day: r.date
                            });
                        };

                        const onSkip = () => {
                            if (state === IngredientState.Removed) return;

                            dispatch({ type: 'skip', ingredient: i });
                        };
                        return <li key={index}><RecipeIngredient ingredient={i} state={state} onAdd={onAdd} onSkip={onSkip}></RecipeIngredient></li>
                    })}
                </ul>
            </li>;
        })}
    </ul>
}

function ShoppingListView() {
    const { aisles, ingredients, recipes, shoppingList } = useLoaderData() as LoaderData;
    const [context, dispatch] = useReducer(
        shoppingListContextReducer,
        { aisles, shoppingList, ingredientToCategory: ingredients, usedIngredients: new Map() }
    );

    const ingredientOptions: IngredientOption[] = [];
    for (let it = ingredients.entries(), entry = it.next(); !entry.done; entry = it.next()) {
        ingredientOptions.push({ name: entry.value[0], category: entry.value[1].name });
    }

    const onAddIngredient = (ingredient: Ingredient) => {
        const category = ingredients.get(ingredient.name);
        dispatch({ type: 'add', category: category?.name, ingredient: { ...ingredient, uniqueId: nextUniqueId() } });
    };

    const buildIngredientInfo = (ingredient: ShoppingListIngredient) => {
        const content = ingredient.sources.map(({ ingredient: i, name }) => {
            const nameContent = name ? name : <i>Egen</i>;
            if (i.quantity) {
                if (i.unit) {
                    return <div key={i.uniqueId}>({displayQuantityValue(i.quantity)} {i.unit}) {nameContent}</div>;
                }
                return <div key={i.uniqueId}>({displayQuantityValue(i.quantity)}) {nameContent}</div>;
            }
            return <div key={i.uniqueId}>{nameContent}</div>;
        });

        return <div className='tooltip'>
            i
            <div className='tooltiptext'>{content}</div>
        </div>;
    };

    return <ShoppingListContext.Provider value={context}>
        <ShoppingListDispatchContext.Provider value={dispatch}>
            <div className='shopping-list-view'>
                <div className='shopping-list-view-recipes'>
                    <h3>Recept</h3>
                    <RecipesWithIngredientsToBuy recipes={recipes}></RecipesWithIngredientsToBuy>
                </div>
                <div className='shopping-list-view-main'>
                    <div className='shopping-list-view-add'>
                        <h3>Ingrediens</h3>
                        <AddIngredient ingredients={ingredientOptions} onAdd={onAddIngredient}></AddIngredient>
                    </div>
                    <div className='shopping-list-view-list'>
                        <h3>Att köpa</h3>
                        <ShoppingList buildIngredientInfo={buildIngredientInfo}></ShoppingList>
                    </div>
                </div>
            </div>
        </ShoppingListDispatchContext.Provider>
    </ShoppingListContext.Provider>;
}

export default ShoppingListView;