import React, { Dispatch, SetStateAction } from "react";

interface EncodeDecodeState<T> {
    encode(v: T): any
    decode(v: any): T
}

export const simpleEncodeDecode: EncodeDecodeState<any> = {
    encode(v: any) {
        return v;
    },
    decode(v: any): any {
        return v;
    }
};

export default function useStickyState<T>(
    defaultValue: T,
    key: string,
    encodeDecode: EncodeDecodeState<T> = simpleEncodeDecode
): [T, Dispatch<SetStateAction<T>>] {
    const [value, setValue] = React.useState<T>(() => {
        const stickyValue = window.localStorage.getItem(key);

        return (stickyValue !== null && stickyValue !== undefined)
            ? encodeDecode.decode(JSON.parse(stickyValue))
            : defaultValue;
    });

    React.useEffect(() => {
        window.localStorage.setItem(key, JSON.stringify(encodeDecode.encode(value)));
    }, [key, value]);

    return [value, setValue];
}
