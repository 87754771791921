import React, { useEffect, useState } from 'react';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import "./EditRecipeView.css";
import imageIcon from "../icons/image.png";
import EditRecipe from '../components/EditRecipe';
import { CooklangRecipe, RecipeWithImages, imageIdToUrl, loadRecipeRaw, saveRecipe, uploadImage } from '../data';
import Recipe, { ParsedRecipeWithMetadata } from '../components/Recipe';
import { SourceDiag } from '../cooklang/parsing';
import { Box, Button, Paper, Stack, Tab, Tabs, TextField, useMediaQuery } from '@mui/material';


export async function loader({ request }: LoaderFunctionArgs) {
    const url = new URL(request.url);
    const id = url.searchParams.get("id") || "";
    if (id) {
        const recipeId = parseInt(id, 10);
        if (recipeId) {
            const recipe = await loadRecipeRaw(recipeId);
            return { recipe };
        }
    }
    
    return {};
}

interface Image {
    dataURL?: string;
    file?: File;
    imageId?: number;
}

interface Slot {
    image?: Image | null;
    type: "main" | "step";
    index: number;
}

function imageToUrl(image: Image | undefined | null): string | undefined {
    if (!image) return undefined;
    if (image.dataURL) return image.dataURL;

    return imageIdToUrl(image.imageId);
}

function toImageArray(recipe: RecipeWithImages | undefined): (Image | null)[] {
    if (!recipe) return [];

    const maxIndex = recipe.images.reduce((max, i) => Math.max(max, i.step), 0);
    const images = new Array<(Image | null)>(maxIndex + 1).fill(null);
    if (typeof recipe.recipe.image_id === "number") {
        images[0] = { imageId: recipe.recipe.image_id };
    }
    for (const i of recipe.images) {
        images[i.step + 1] = { imageId: i.image_id };
    }

    return images;
}

function compareImages(a: (Image | null)[], b: (Image | null)[]): boolean {
    const lastImageA = a.reduce((prev, i, curr) => i ? (curr + 1) : prev, 0);
    const lastImageB = b.reduce((prev, i, curr) => i ? (curr + 1) : prev, 0);
    if (lastImageA !== lastImageB) return false;

    for (let i = 0; i < lastImageA; ++i) {
        if (a[i] !== b[i]) return false;
    }

    return true;
}

function withImageUrls(name: string, recipe: CooklangRecipe, images: (Image | null)[]): ParsedRecipeWithMetadata {
    const stepImages = new Map<number, string>();
    for (let i = 1; i < images.length; ++i) {
        const imageUrl = imageToUrl(images[i]);
        if (imageUrl) {
            stepImages.set(i, imageUrl);
        }
    }

    return {
        name,
        recipe,
        image: imageToUrl(images[0]),
        stepImages
    };
}

export interface DiagnosticProps {
    source: string;
    diagnostic: SourceDiag;
    lineBreaks: number[];
}


function Diagnostic({ source, diagnostic: d, lineBreaks }: DiagnosticProps) {
    const offset = d.labels[0].start;
    const endOffset = d.labels[0].end;
    let lineNumber = lineBreaks.findIndex(lineBreakOffset => offset < lineBreakOffset);
    let lineStartOffset: number;
    let lineEndOffset: number;
    if (lineNumber === -1) {
        lineNumber = lineBreaks.length + 1;
        lineStartOffset = lineBreaks[lineBreaks.length - 1];
        lineEndOffset = source.length;
    }
    else {
        lineStartOffset = lineNumber === 0 ? -1 : lineBreaks[lineNumber - 1];
        lineEndOffset = lineBreaks[lineNumber] ?? source.length;
        lineNumber += 1;
    }

    const errorPart = source.slice(offset, endOffset);
    let errorPrefixLine = source.slice(lineStartOffset + 1, offset);
    let errorSuffixLine = source.slice(endOffset, lineEndOffset);

    const prefixLimit = 30;
    const suffixLimit = 30;
    if (errorPrefixLine.length > prefixLimit) {
        errorPrefixLine = "... " + errorPrefixLine.slice(errorPrefixLine.length - prefixLimit);
    }
    if (errorSuffixLine.length > suffixLimit) {
        errorSuffixLine = errorSuffixLine.slice(0, suffixLimit) + " ...";
    }

    const alignSuggestion = errorPrefixLine.length < 10 ? "suggestion-align-left" : "";

    const severity = d.severity.toLowerCase();
    return <Paper>
        <Stack margin={"15px"} spacing={"15px"} marginBottom={"45px"}>
            <span><span className={"diagnostic-" + severity}>{severity} </span>{d.message}</span>

            <Stack direction={"row"} spacing={"30px"}>
                <span className='error-linenumber'>{lineNumber}</span>
                <span>
                    <span className='error-code'>{errorPrefixLine}</span>
                    <span className='error-highlight'><span className='error-code'>{errorPart}</span>
                        <span className={"error-suggestion " + alignSuggestion}>{d.labels[0].message}</span>
                    </span>
                    <span className='error-code'>{errorSuffixLine}</span>
                </span>
            </Stack>
        </Stack>
    </Paper>;
}

export interface DiagnosticsProps {
    source: string;
    diagnostics: SourceDiag[];
}

function Diagnostics({ source, diagnostics }: DiagnosticsProps) {
    const lineBreaks = Array.from(source.matchAll(/\n/g)).map(match => match.index!);
    return <Stack spacing={"15px"} margin={"15px"}>
        <h3>Fel i recept</h3>
            {diagnostics.map((d, index) => {
                return <Diagnostic key={index} source={source} diagnostic={d} lineBreaks={lineBreaks}></Diagnostic>
            })}
    </Stack>;
}

function ImageButton({ image, onImageChanged }: { image?: Image | null, onImageChanged: (image?: Image & { imageId?: number; }) => void }) {
    const onChange = async (imageList: ImageListType, addUpdateIndex?: number[] | undefined) => {
        // data for submit
        let image = imageList[0];
        onImageChanged(image);

        if (image?.file) {
            image.imageId = await uploadImage(image.file);
            onImageChanged(image);
        }
    };

    return <ImageUploading
        value={image ? [image] : []}
        onChange={onChange}
        dataURLKey='data_url'
        maxFileSize={2048*1024}>
        {
            ({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
            }) => {
                let classNames = ["upload_image_button-wrapper"];
                if (isDragging) {
                    classNames.push("dragging");
                }

                const image = imageList[0];
                const imageUrl = image?.['data_url'] || imageIdToUrl(image?.['imageId']);
                // write your building UI
                return <div className={classNames.join(" ")} onClick={onImageUpload} {...dragProps}>
                    <img src={imageUrl || imageIcon} alt="" width={imageUrl ? "100" : "30"} />
                    {image ? <button onClick={(e) => { e.stopPropagation(); onImageRemoveAll();}}>Remove</button> : <></>}
                </div>
            }
        }
    </ImageUploading>
}

function ImageHandler({ slots, onImageChanged }: { slots: Slot[]; onImageChanged: (index: number, image?: Image) => void }) {
    return <Stack direction='row' spacing='5px' flexWrap='wrap' useFlexGap>
        {slots.map((s, index) => {
            return <ImageButton key={index} image={s.image} onImageChanged={image => onImageChanged(index, image)}></ImageButton>
        })}
    </Stack>
}


function EditRecipeView() {
    const { recipe } = useLoaderData() as { recipe: RecipeWithImages | undefined; };
    const largeScreen = useMediaQuery('(min-width:900px)');
    const [tab, setTab] = useState<number>(0);
    const [id, setId] = useState<number | undefined>(recipe?.recipe.id);

    const [oldName, setOldName] = useState<string>(recipe?.recipe.name || "");
    const [oldText, setOldText] = useState<string>(recipe?.recipe.recipe || "");
    const [oldImages, setOldImages] = useState<(Image | null)[]>(toImageArray(recipe));

    const [name, setName] = useState<string>(oldName);
    const [text, setText] = useState<string>(oldText);
    const [images, setImages] = useState<(Image | null)[]>(oldImages);

    const hasChanges = oldName !== name || oldText !== text || !compareImages(oldImages, images);
    const allImagesAreUploaded = images.every(i => !i || typeof i.imageId == "number");

    const [parsedRecipe, setParsedRecipe] = useState<CooklangRecipe | undefined>(undefined);
    const [parseDiagnostics, setParseDiagnostics] = useState<SourceDiag[]>([]);
    const [isParsingRecipe, setIsParsingRecipe] = useState<boolean>(false);

    const requestParseRecipe = (text: string) => {
        setIsParsingRecipe(true);
        import('wasm-cooklang-parser').then(wasm => {
            const res = wasm.parse(text);
            setParsedRecipe(res.output);
            setParseDiagnostics(res.report);
        })
        .finally(() => {
            setIsParsingRecipe(false);
        })
    };

    useEffect(() => {
        if (parsedRecipe || isParsingRecipe) return;

        requestParseRecipe(text);
    }, [parsedRecipe, isParsingRecipe, text]);

    const onSave = async () => {
        const recipeId = await saveRecipe({
            id,
            name,
            images: images.map(i => i ? i.imageId! : null),
            recipe: text
        });
        setId(recipeId);
        setOldName(name);
        setOldText(text);
        setOldImages(images);
    };

    const onTextChanged = (text: string) => {
        setText(text);
        if (isParsingRecipe) {
            return;
        }
        requestParseRecipe(text);
    };

    const onImageChanged = (index: number, image?: Image) => {
        const newImage = images.slice();
        if (!image) {
            delete newImage[index];
        }
        else {
            newImage[index] = image;
        }
        setImages(newImage);
    };

    let slots: Slot[] = [
        { type: "main", index: 0, image: images[0] }
    ];

    if (parsedRecipe) {
        for (const section of parsedRecipe.sections) {
            for (const _ of section.content) {
                const index = slots.length;
                slots.push({ type: "step", index, image: images[index] });
            }
        }
    }

    if (largeScreen) {
        return (
            <Stack flexGrow='1' direction='column'>
                <Box flexGrow='1' sx={{ position: 'relative' }}>
                    <Stack direction='row' sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                        <Stack direction='column' sx={{ width: '50%', height: '100%' }}>
                            <div className='editor-preview-item-header'>
                                <Stack direction='row'>
                                    <span>Editor (<a href='https://cooklang.org/docs/spec/'>Cooklang</a>)</span>
                                    <Stack direction='row' justifyContent='end' flexGrow='1' spacing='5px'>
                                        <TextField
                                            hiddenLabel
                                            variant="filled"
                                            size='small'
                                            value={name}
                                            onChange={e => setName(e.target.value)}/>
                                        <Button
                                            variant='contained'
                                            onClick={onSave}
                                            disabled={!name || !allImagesAreUploaded}
                                        >Save{hasChanges ? "*" : ""}</Button>
                                    </Stack>
                                </Stack>
                            </div>
                            <Box flexGrow='1' sx={{ position: 'relative' }}>
                                <Box sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                                    <EditRecipe text={text} onTextChanged={onTextChanged} diagnostics={parseDiagnostics}></EditRecipe>
                                </Box>
                            </Box>
                        </Stack>
                        <Stack direction='column' sx={{ width: '50%', height: '100%' }}>
                            <div className='editor-preview-item-header'>
                                Preview
                            </div>
                            <Box flexGrow='1' sx={{ position: 'relative' }}>
                                <Box sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, overflowY: 'scroll' }}>
                                    {parsedRecipe ?
                                        <Recipe recipe={withImageUrls(name, parsedRecipe, images)}></Recipe> :
                                        (parseDiagnostics.length !== 0 ? <Diagnostics source={text} diagnostics={parseDiagnostics}></Diagnostics> : <>Loading...</>)}
                                </Box>
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
                <ImageHandler slots={slots} onImageChanged={onImageChanged}></ImageHandler>
            </Stack>
        );
    }
    else {
        return (
            <Stack direction='column' sx={{ flexGrow: '1' }}>
                <Stack direction='row' justifyContent='end' padding='5px' spacing='5px'>
                    <TextField label="Namn" variant="standard" value={name} onChange={e => setName(e.target.value)} sx={{ flexGrow: '1'}}/>
                    <Button variant='contained' onClick={onSave} disabled={!name || !allImagesAreUploaded}>Save{hasChanges ? "*" : ""}</Button>
                </Stack>
                <Tabs value={tab} onChange={(_e, newTab) => setTab(newTab)}>
                    <Tab label='Editor'></Tab>
                    <Tab label='Images'></Tab>
                    <Tab label='Preview'></Tab>
                </Tabs>
                <Stack direction='row' flexGrow='1'>
                    <Box hidden={tab !== 0} sx={{ minHeight: '100%', width: '100%' }}>
                        <EditRecipe text={text} onTextChanged={onTextChanged} diagnostics={parseDiagnostics}></EditRecipe>
                    </Box>
                    <Box hidden={tab !== 1}>
                        <ImageHandler slots={slots} onImageChanged={onImageChanged}></ImageHandler>
                    </Box>
                    <Box hidden={tab !== 2}>
                        {parsedRecipe ?
                            <Recipe recipe={withImageUrls(name, parsedRecipe, images)}></Recipe> :
                            (parseDiagnostics.length !== 0 ? <Diagnostics source={text} diagnostics={parseDiagnostics}></Diagnostics> : <>Loading...</>)}
                    </Box>
                </Stack>
            </Stack>
        );
    }

}

export default EditRecipeView;
