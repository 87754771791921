import React from 'react';

export interface SelectPortionsProps {
    scaling: number;
    onSelectPortion: (scaling: number) => void;
    disabled?: boolean;
}

function SelectPortions({ scaling, onSelectPortion, disabled }: SelectPortionsProps) {
    return <input
        type='number'
        value={scaling}
        onChange={e => onSelectPortion(parseInt(e.target.value))}
        min='1'
        disabled={disabled}
    ></input>;
}

export default SelectPortions;