import React, { useCallback, useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup, { toggleButtonGroupClasses } from "@mui/material/ToggleButtonGroup";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Stack from "@mui/material/Stack";
import { styled, Typography } from "@mui/material";

export const enum FilterState {
    None,
    Include,
    Exclude
}

export interface IncludeExcludeFilterControlProps {
    label: string;
    value?: FilterState;
    onChange: (value: FilterState) => void;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        borderRadius: theme.shape.borderRadius,
        [`&.${toggleButtonGroupClasses.disabled}`]: {
        },
    },
    [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    },
}));


export default function IncludeExcludeFilterControl({ value, label, onChange }: IncludeExcludeFilterControlProps) {
    const handleAlignment = (
        _event: React.MouseEvent<HTMLElement>,
        newFilter: FilterState | null,
    ) => {
        if (newFilter !== null) {
            onChange(newFilter);
        }
    };

    const onIncludeClicked = () => {
        if (value === FilterState.Include) {
            onChange(FilterState.None);
        }
    };
    const onExcludeClicked = () => {
        if (value === FilterState.Exclude) {
            onChange(FilterState.None);
        }
    };

    return <Stack direction='row'>
        <StyledToggleButtonGroup
            value={value ?? FilterState.None}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
        >
            <ToggleButton value={FilterState.Include} aria-label="left aligned" onClick={onIncludeClicked}>
                <VisibilityIcon />
            </ToggleButton>
            <ToggleButton value={FilterState.Exclude} aria-label="right aligned" onClick={onExcludeClicked}>
                <VisibilityOffIcon />
            </ToggleButton>
        </StyledToggleButtonGroup>
        <Typography sx={{ margin: 'auto', marginLeft: '5px' }}>{label}</Typography>
    </Stack>;
}
