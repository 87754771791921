import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { PlannedMeal, Recipe, dateToString, plannedMeals as getPlannedMeals, imageIdToUrl } from '../data';
import { addDay, getWeekDay, isSameDay } from '../dates';
import LinkButton from '../components/LinkButton';
import { AuthStatus, useAuthContext } from '../contexts/AuthProvider';
import RecipeCard from '../components/RecipeCard';
import { Box, Divider, Stack, Typography } from '@mui/material';

interface Day {
    date: Date;
    recipes: PlannedMeal[];
}

function getDateName(date: Date): string {
    const today = new Date();
    if (isSameDay(date, addDay(today, -1))) return "Igår";
    if (isSameDay(date, today)) return "Idag";
    if (isSameDay(date, addDay(today, 1))) return "Imorgon";
    if (isSameDay(date, addDay(today, 2))) return "Övermorgon";

    return dateToString(date)!;
}

function DayRecipe({ meal }: { meal: PlannedMeal }) {
    const recipe = meal.recipe;
    const link = `/recipe/${recipe.id}${meal.servings ? "?servings=" + meal.servings : ""}`;

    return <RecipeCard recipe={recipe} link={link}></RecipeCard>;
}

function Day({ day, highlight }: { day: Day, highlight: boolean; }) {
    const recipes = day.recipes.map((r, index) => <DayRecipe key={index} meal={r}></DayRecipe>);

    const weekDay = getWeekDay(day.date);
    const dateName = getDateName(day.date);

    return <Stack
        position='relative'
        direction={{ md: 'row' }}
        spacing={{ xs: '15px' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        zIndex='0'
    >
        <Stack
            width='150px'
            minWidth='150px'
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-end'
            sx={{ display: { xs: 'none', md: 'flex' }  }}
            useFlexGap
        >
            <Typography variant='h6'>{dateName}</Typography>
            <Typography variant='overline'>{weekDay}</Typography>
        </Stack>
        <Divider orientation='vertical' flexItem sx={{ display: { xs: 'none', md: 'block' } }}></Divider>
        <Divider
            orientation='horizontal'
            flexItem
            textAlign='left'
            sx={{ display: { md: 'none' }, padding: '0 15px' }}
        >
            <Typography>
                {dateName}
            </Typography>
        </Divider>
        <Stack
            direction='row'
            spacing={{ xs: '15px' }}
            useFlexGap
            sx={{
                paddingLeft: '15px',
                justifyContent: 'flex-start'
            }}
            flexGrow='1'
            flexWrap='wrap'
        >
            {recipes.length ? recipes : <div className='home-day-recipe unplanned'>Inget planerat</div>}
        </Stack>
        {!highlight ?
            <></> :
            <Box
                sx={{
                    position: 'absolute',
                    backgroundColor: '#9bdaff',
                    left: '50%',
                    transform: 'translate(-50%, 0%)',
                    height: '110%',
                    width: '101vw',
                    minWidth: '101vw',
                    margin: '0 !important',
                    zIndex: -1
                }}
            ></Box>}
    </Stack>;
}

function App() {
    const todayRef = useRef<HTMLDivElement>(null);
    const auth = useAuthContext();
    const currentHouseholdId = auth.status === AuthStatus.SignedIn ? auth.currentHousehold : undefined;
    const today = new Date();

    const [plannedMeals, setPlannedMeals] = useState<Day[] | undefined>();

    useEffect(() => {
        const daysToShow = new Array(15).fill(0).map((_, index) => addDay(today, index - 5));
        getPlannedMeals(currentHouseholdId, daysToShow[0], daysToShow[daysToShow.length - 1]).then(plannedMeals => {
            let days = new Map<string, Day>();
            for (const meal of plannedMeals) {
                let entry = days.get(meal.date);
                if (!entry) {
                    days.set(meal.date, entry = { date: new Date(meal.date), recipes: [] });
                }
                entry.recipes.push(meal);
            }

            // Add remaining days
            for (const day of daysToShow) {
                const key = dateToString(day)!;
                if (!days.has(key)) {
                    days.set(key, { date: day, recipes: [] });
                }
            }

            const values = Array.from(days.values()).sort((a, b) => a.date.getTime() - b.date.getTime())
            setPlannedMeals(values);
        });
    }, [currentHouseholdId]);

    useEffect(() => {
        todayRef.current?.scrollIntoView();
    }, [plannedMeals])

    return <Box sx={{ backgroundColor: 'rgb(223, 239, 255)', pb: { md: '15px' }, minHeight: "100%", flexGrow: '1' }}>
        <Stack
            sx={{
                maxWidth: { md: '1000px' },
                ml: 'auto',
                mr: 'auto',
            }}
            spacing='30px'
        >
            {plannedMeals ? plannedMeals.map(meal => {
                    const isToday = isSameDay(today, meal.date);
                    return <div key={meal.date.toISOString()} ref={isToday ? todayRef : null}><Day day={meal} highlight={isToday}></Day></div>;
                }) : <span>Loading...</span>}
        </Stack>
    </Box>;
}

export default App;
