import { Outlet, ScrollRestoration } from "react-router-dom";
import AppBar from "./AppBar";
import AuthProvider, { AuthIsNotSignedIn, AuthIsSignedIn } from "../contexts/AuthProvider";
import LoginPage from "./LoginPage";

export default function Root() {
    return <AuthProvider>
        <AuthIsSignedIn>
            <AppBar></AppBar>
            <Outlet />
        </AuthIsSignedIn>
        <AuthIsNotSignedIn>
            <LoginPage></LoginPage>
        </AuthIsNotSignedIn>
        <ScrollRestoration/>
    </AuthProvider>;
}