const weekDay = [
    "Söndag",
    "Måndag",
    "Tisdag",
    "Onsdag",
    "Torsdag",
    "Fredag",
    "Lördag",
];

const shortWeekDay = [
    "Sö",
    "Må",
    "Ti",
    "On",
    "To",
    "Fr",
    "Lö",
];

const months = [
    "Januari",
    "Februari",
    "Mars",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "Augusti",
    "September",
    "Oktober",
    "November",
    "December",
];

export function getWeekDay(d: Date): string {
    return weekDay[d.getDay()];
}

export function getShortWeekDay(d: Date): string {
    return shortWeekDay[d.getDay()];
}

export function getWeekDayIndex(d: Date): number {
    const index = d.getDay();
    return index === 0 ? 6 : (index - 1);
}

export function getMonth(d: Date): string {
    return months[d.getMonth()];
}

export function addDay(d: Date, days: number): Date {
    const res = new Date(d);
    res.setDate(res.getDate() + days);
    return res;
}

export function isSameDay(a: Date, b: Date): boolean {
    return a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();
}

export function dateToString(d: Date): string {
    return d.toISOString().replace(/T.*Z/, "");
}

export function datesInRange(start: Date, end: Date): Date[] {
    let dates: Date[] = [start];
    let curr: Date = start;
    while (!isSameDay(curr, end)) {
        curr = addDay(curr, 1);
        dates.push(curr);
    }

    return dates;
}
