import React, { useEffect, useState } from 'react';
import Editor, { useMonaco } from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import { IngredientsCompletionProvider, languageId, setupLanguage, themeId } from '../cooklangSupport';
import { getIngredients } from '../data';
import { SourceDiag } from '../cooklang/parsing';

const options: editor.IStandaloneEditorConstructionOptions = {
    wordWrap: "on"
};

export interface EditRecipeProps {
    text?: string;
    onTextChanged?: (value: string) => void;
    diagnostics: SourceDiag[];
}

function EditRecipe({ text, onTextChanged, diagnostics }: EditRecipeProps) {
    const monaco = useMonaco();
    const [editor, setEditor] = useState<editor.IStandaloneCodeEditor | undefined>();
    const [languageInit, setLanguageInit] = useState<boolean>(false);
    const [ingredients, setIngredients] = useState<string[]>([]);

    useEffect(() => {
        getIngredients().then(aisles => {
            let res: string[] = [];
            for (const aisle of aisles) {
                res.push(...aisle.ingredients);
            }
            setIngredients(res);
        });
    }, [setIngredients]);

    useEffect(() => {
        if (!monaco) return;

        setupLanguage(monaco);
        setLanguageInit(true);
    }, [monaco]);

    useEffect(() => {
        if (!monaco) return;

        const instance = monaco.languages.registerCompletionItemProvider(
            languageId,
            new IngredientsCompletionProvider(ingredients)
        );

        return () => instance.dispose()
    }, [monaco, ingredients]);

    useEffect(() => {
        if (!editor || !monaco) return;

        const model = editor.getModel();
        if (!model) return;

        let markers: editor.IMarkerData[] = [];
        for (const d of diagnostics) {
            for (const l of d.labels) {
                const start = model.getPositionAt(l.start);
                const end = model.getPositionAt(l.end);
                markers.push({
                    severity: d.severity === "Error" ? monaco.MarkerSeverity.Error : monaco.MarkerSeverity.Warning,
                    message: d.message,
                    startColumn: start.column,
                    startLineNumber: start.lineNumber,
                    endColumn: end.column,
                    endLineNumber: end.lineNumber
                });
            }
        }

        monaco.editor.setModelMarkers(model, "owner", markers);
    }, [monaco, editor, diagnostics]);

    if (!languageInit) {
        return <div>Loading...</div>
    }

    return (<Editor
        language={languageId}
        theme={themeId}
        defaultValue={text}
        value={text}
        options={options}
        onMount={(editor) => setEditor(editor)}
        onChange={(e) => onTextChanged?.(e || "")}/>);
}

export default EditRecipe;